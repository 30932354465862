/* Global imports */
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import slugify from "slugify";
/* Local imports */
import Label from "../components/label";
import { getFilteredSearchLink } from "../helper/link";
import { getValue } from "../helper/translation";

/* Component definition */
const PastForumItem = ({
  id,
  agendas,
  description,
  endDate,
  location,
  startDate,
  title,
  year,
  node_locale,
  summaryContent,
}) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);
  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  const SLUGIFY_OPTIONS = {
    replacement: "-", // replace spaces with replacement
    remove: /[^A-Za-z\-\_0-9 ]/, // regex to remove characters
    lower: true, // result in lower case
  };

  const link = true;
  const footer = (
    <div className="teaser-card-footer">
      {link && (
        <a
          href={`/past-event/${slugify(title, SLUGIFY_OPTIONS)}`}
          className="teaser-card-footer-link"
        >
          {getValue("actions.readMore", localizedMicrocopy)}
          {/* {readMoreLabelText} */}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="teaser-card-footer-icon"
          />
        </a>
      )}
    </div>
  );

  return (
    // <h3>ITEM</h3>
    <article
      className={`teaser-card teaser-card--publication pastForumItemContainer`}
      // ${className}
      // } ${big ? "big" : ""} ${video ? "video" : ""}
      // `}
    >
      {/* <div className="d-flex"> */}
      <Row className="pastEventsRow">
        <Col sm={3} lg={4}>
          {summaryContent && summaryContent.asideGalleryImages ? (
            <Img
              fluid={summaryContent.asideGalleryImages[0].image.fluid}
              className="photo"
            />
          ) : (
            <div>IMAGE</div>
          )}
        </Col>
        <Col
          sm={0}
          md={9}
          lg={8}
          className="d-flex align-items-center contentContainer"
        >
          {/* <div className="d-flex align-items-center contentContainer"> */}
          <div className="teaser-card-content d-flex flex-column justify-content-between">
            <div>
              <Label key={1} text={year} type="primary" className="tag" />
              {title && <h1 className="teaser-card-title">{title}</h1>}
              {description && <p className="teaser-card-text">{description}</p>}
              <a
                href={`/past-event/${slugify(title, SLUGIFY_OPTIONS)}`}
                className="teaser-card-footer-link"
              >
                {getValue("actions.readMore", localizedMicrocopy)}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="lg"
                  className="teaser-card-footer-icon"
                />
              </a>
            </div>
          </div>
          {/* </div> */}
        </Col>
      </Row>
      {/* </div> */}
    </article>
  );
};
/* PropTypes */
PastForumItem.propTypes = {};
PastForumItem.defaultProps = {};

/* Local utility functions */

/* Styles */

export default PastForumItem;
