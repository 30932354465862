import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import PastForumItem from "../components/pastForumItem";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const PastForums = ({ data: { allEvents }, pageContext }) => {
  const {
    name,
    contentComponents = [],
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;
  const siteSettings = settings;

  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  const pastEvents = [];
  allEvents.nodes.map(evnt => {
    if (moment(evnt.endDate).isBefore(todayDate) && evnt.moveToPastEvents) {
      pastEvents.push(evnt);
    }
  });

  const arrYears = [];
  pastEvents.map(evnt => {
    if (!arrYears.find(y => y === moment(evnt.endDate).format("YYYY"))) {
      arrYears.push(moment(evnt.endDate).format("YYYY"));
    }
  });

  // const objEventsByYear = {};
  // arrYears.map(year => {
  //   objEventsByYear[`events${year}`] = []
  //   pastEvents.map(evnt => {
  //     if (moment(evnt.EndDate).format("YYYY") === year){
  //       objEventsByYear[`events${year}`].push({...evnt, year})
  //     }
  //   })
  // })

  const { microcopy } = settings;
  return (
    <Layout
      siteSettings={siteSettings}
      showBreadcrumbNavigation={showBreadcrumbNavigation}
      showNews={showNews}
    >
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Container fluid className="pastForumsPageGeneral">
        {contentComponents &&
          renderDynamicComponents(contentComponents, node_locale)}
        <Row>
          <Col>
            <div className="eventsContainer">
              {arrYears
                .sort((a, b) => b - a)
                .map(year => {
                  const events = pastEvents.filter(
                    evnt => moment(evnt.endDate).format("YYYY") === year
                  );
                  return events.map(event => {
                    const params = { ...event, year };
                    return <PastForumItem {...params} />;
                  });
                })}
            </div>
          </Col>
        </Row>
      </Container>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
    </Layout>
  );
};
export const pageQuery = graphql`
  query($node_locale: String!) {
    allEvents: allContentfulEvent(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        id
        contentful_id
        description
        title
        startDate(fromNow: false)
        endDate(fromNow: false)
        node_locale
        moveToPastEvents
        summaryContent {
          title
          node_locale
          longDescription {
            json
          }
          infoBoxes {
            ... on ContentfulInfo {
              title
              description {
                description
              }
            }
            ... on ContentfulAdditionalInfo {
              title
              body {
                json
              }
              link {
                url
                linkText
              }
              internalLink {
                slug
              }
            }
          }
          video {
            embedLink
            thumbnailLandscape {
              imageLandscape {
                fluid {
                  srcSet
                  src
                  base64
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
          asideGalleryImages {
            image {
              fixed(width: 150, quality: 100) {
                src
                srcSet
                srcSetWebp
                srcWebp
                height
                width
              }
              fluid {
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
        agendas {
          id
          contentful_id
          node_locale
          title
          agendaItems {
            id
            node_locale
            startTime(fromNow: false)
            endTime(fromNow: false)
            title
            withoutSessions
            sessions {
              id
              contentful_id
              description {
                description
              }
              format {
                title
              }
              imageGallery {
                name
                imageLandscape {
                  fluid {
                    base64
                    sizes
                    src
                    srcSet
                    srcWebp
                    aspectRatio
                  }
                }
              }
              hosts {
                name
                image {
                  fluid {
                    srcSet
                  }
                }
              }
              topic {
                ... on ContentfulTopic {
                  id
                  contentful_id
                  name
                }
              }
              mainTopic
              moderators {
                biography {
                  json
                }
                image {
                  fluid {
                    srcSet
                  }
                  title
                }
                name
                organization {
                  name
                }
              }
              name
              info {
                id
                title
                description {
                  description
                }
              }
              reader {
                json
                reader
              }
              region {
                name
              }
              room {
                title
              }
              secondaryTopic
              speakers {
                biography {
                  json
                }
                image {
                  fluid {
                    srcSet
                  }
                  title
                }
                name
                organization {
                  name
                }
              }
              startTime(fromNow: false)
              subtitle
              title
            }
          }
        }
      }
    }
  }
`;
// export const pageQuery = graphql`
//   query($node_locale: String!, $todayDate: Date ) {
//     allEvents: allContentfulEvent(filter: {endDate: {lt: $todayDate}, node_locale: { eq: $node_locale }}){
//       edges{
//         node{
//           agendas{
//             title
//           }
//         }
//       }
//     }
//   }
// `;

export default PastForums;
